import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';
@inject(DialogController,ProfileService,JwtService,Router,LoginService)
export class ShowHistoryComponent {
    types = [];
    isDragging = false;
    dialog;
    static inject = [Element];
    constructor(controller, ProfileService, JwtService,router,loginService) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.router = router;
        this.loginService = loginService;
        this.empID = this.jwtService.getEmployeeId();
    }
    async bind() {
    setTimeout(()=>{       
      Scrollbar.init(document.querySelector('.contact-history-scrollbar'));
    },100)
    }
    attached() {

        this.profileService.EmployeeFamilyRelationship()
            .then(data => {
                this.options= data;
            });
        this.profileService.CountryCodesDropdown()
            .then(data => {
                this.codes = data;
            });
        this.profileService.CountryNamesDropdown()
            .then(data => {
                this.countryNames = data;
            });
        this.profileService.CountryStatesDropdown()
            .then(data => {
                this.states = data;
            });
        this.profileService.GetEmergencyContactDetails(this.empID)
            .then(data => {
                this.emergencycontactdata = data;
            });
    }
    cancel() {
        this.controller.cancel();
    }
    activate(data){
        this.emergencyhistory = data;
        this.address1 = data.Address1;
        this.address2 = data.Address2;
        this.name = data.Name;
        this.Relation = data.Relationship;
        this.mobile = data.Mobile;
        this.mobilid = data.MobileCountryCode;
        this.phone = data.HomeContactNo;
        this.phoneid = data.HomeCountyCode;
        this.workcontact = data.WorkContactNo;
        this.workcontactid = data.WorkCountryCode;
        this.workectension = data.WorkExtension;
        this.city = data.City;
        this.Country = data.CountryID;
        this.StateNames = data.StateID;
        this.pincode = data.PinCode;
        this.AddressID = data.AddressID;
        this.PersonId = data.PersonId;
        this.PersonEmergencyContactID = data.PersonEmergencyContactID;
        this.HomeExtension = data.HomeExtension;
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
}



