import {inject,computedFrom } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import {validateTrigger, ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
//import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';
@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService,Element)
export class AddEmergencyComponent {
    types = [];
    countryNames = [];
    contactnamevalidError = false;
    mobileNo = "";
    phoneNo = "";
    workcontact = "";
    isValidationMessageVisible = false;
    constructor(controller, ProfileService, JwtService,controllerFactory,router,loginService,Element) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.phoneNo = '';
        this.phoneNoError = [];
        this.mobileNo = '';
        this.mobileNoError = [];
        this.workcontact = '';
        this.workcontactError = [];
        this.pincode = '';
        this.pincodeError = [];
        this.router = router;
        this.loginService = loginService;
        this.empID = this.jwtService.getEmployeeId();
        this.element = Element;

        ValidationRules
            .ensure('name').required().withMessage("Name is required")
            .ensure('address1').required().withMessage("Address is required")
            .ensure('phoneNo')
            .displayName('Phone number')
            .matches(/^\d{10}$/)
            .withMessage('Please enter valid phone number')
            .ensure('mobileNo')
            .displayName('Mobile number')
            .matches(/^\d{10}$/)
            .withMessage('Please enter valid mobile number')
            .ensure('workcontact')
            .displayName('Work contact number')
            .matches(/^\d{10}$/)
            .withMessage('Please enter valid work contact number')
            .ensure('workextension')
            .maxLength(6)
            .withMessage('Work phone extension field maximum length is 6.')
            .ensure('pincode')
            .displayName('Pin code')
            .matches(/^\d+$/, { message: '${$displayName} must contain only numeric values.' })
            .withMessage('Please enter valid pin code.')
            .on(this);
        ValidationRules.customRule(
            'numericPincode',
            (value, obj) => /^\d+$/.test(value),
            'Pin code must contain only numeric values.'
        );
    }
    areAllContactsEmpty() {
        return !this.mobileNo && !this.phoneNo && !this.workcontact;
    }
    handleInput() {
        if (!this.areAllContactsEmpty()) {
            this.isValidationMessageVisible = false; // Hide the validation message
        }
    }
    validate() {
        this.validationController.validate()
            .then(results => {
                this.pincodeError = results.filter(result => result.propertyName === 'pincode');
            });
    }
    async bind() {
        setTimeout(()=>{       
          Scrollbar.init(document.querySelector('.contact-history-scrollbar'));
        },100)
    }
    attached() {
        this.profileService.EmployeeFamilyRelationship()
            .then(data => {
                this.options = data;
            });
        this.profileService.CountryCodesDropdown()
            .then(data => {
                this.codes = data;
            });
        this.profileService.CountryNamesDropdown()
            .then(data => {
                this.countryNames = data;
            });
        this.profileService.CountryStatesDropdown()
            .then(data => {
                this.states = data;
            });
    }
    cancel() {
        this.controller.cancel();
    }
    validateContatctName(event) {
        const inputElement = event.target;
        const inputValue = inputElement.value;
        const onlyCharactersRegex = /^[A-Za-z\s]*$/;
        if (!onlyCharactersRegex.test(inputValue)) {
            this.contactnamevalidError = true;
        } else {
            this.contactnamevalidError = false;
        }
    }
    async selectCountry(data){
      const states = await this.profileService.GetStatesDropdown();
      this.statesDropdown = states.filter(option => option.CountryID == data );
    }

    SaveEmergencyContact() {
        const refreshEvent = new CustomEvent('refreshDataEmergencyContact');
        event.preventDefault();
        this.controllerFactory.validate()
            .then(result => {
            if (this.areAllContactsEmpty()) {
                this.isValidationMessageVisible = true;
                const errorMessages = document.querySelectorAll(".error-messages");
                if (errorMessages.length > 0) {
                    //errorMessages[0].scrollIntoView({ behavior: "smooth" });
                    // Scroll to the parent div with class 'errorfield' if error messages exist
                    const errorFieldParent = document.querySelector('.common-parent');
                    if (errorFieldParent) {
                        errorFieldParent.scrollIntoView({ behavior: 'smooth' });
                    }
                }

                } else {
                    this.isValidationMessageVisible = false;
                if (result.valid && !this.contactnamevalidError) {
                    let data = {
                        EmployeeID: this.empID,
                        Address1: this.address1,
                        Address2: this.address2,
                        City: this.city,
                        StateID: this.state,
                        CountryID: this.country,
                        PinCode: this.pincode,
                        AddressID: this.addressID,
                        Name: this.name,
                        EmployeeDependentConfigID: this.Relationship,
                        HomeContactNo: this.phoneNo,
                        Mobile: this.mobileNo,
                        WorkContactNo: this.workcontact,
                        HomeCountyCode: this.phonetypeId,
                        MobileCountryCode: this.mobiletypeid,
                        WorkCountryCode: this.workcontactid,
                        HomeExtension: this.workphone,
                        WorkExtension: this.workextension,
                        PersonEmergencyContactID: this.PersonEmergencyContactID,
                    };
                    const EmergencyContactValidatecheck = {
                        EmployeeID: this.empID,
                        Name: this.name,
                        PersonEmergencyContactID: this.PersonEmergencyContactID,
                    };
                    this.profileService.EmergencyContactAvoidDuplicate(EmergencyContactValidatecheck).then(name => {
                        if (name === 1) {
                            this.errorMessage = true;
                            this.errorMessage = 'Name already exists.';
                            const errorMessages = document.querySelectorAll(".error-messages");
                            if (errorMessages.length > 0) {
                                errorMessages[0].scrollIntoView({ behavior: "smooth" });
                                // Scroll to the parent div with class 'errorfield' if error messages exist
                                const errorFieldParent = document.querySelector('.common-parent');
                                if (errorFieldParent) {
                                    errorFieldParent.scrollIntoView({ behavior: 'smooth' });
                                }
                            }
                            setTimeout(() => {
                                this.errorMessage = null
                            }, 5000);
                        }
                        else {
                            this.profileService.SaveEmergencyContact(data)
                                .then(() => {
                                    this.controller.cancel();
                                    this.profileService.GetEmergencyContactDetails(this.empID)
                                        .then(data => {
                                            this.emergencycontactdata = data;
                                            document.dispatchEvent(refreshEvent);
                                        });
                                });
                        }
                    });
                }
                else{
                    const errorMessages = document.querySelectorAll(".error-messages");
                    if (errorMessages.length > 0) {
                        errorMessages[0].scrollIntoView({ behavior: "auto" });
                    }
                }
            }
            });

    }
}
