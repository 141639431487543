import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService)
export class Addcontactcomponent {
    types = [];
    filteredAddressTypes = [];
    existingContact = [];
    isDragging = false;
    dialog;
    static inject = [Element];

    constructor(controller,ProfileService,JwtService,controllerFactory,router,loginService){
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService = JwtService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.phoneNo = '';
        this.phoneNoError = [];
        this.mobileNo = '';
        this.mobileNoError = [];
        this.fax = '';
        this.faxError = [];
        this.pincode = '';
        this.pincodeError = [];
        this.router = router;
        this.loginService = loginService;
        this.empID = this.jwtService.getEmployeeId();

        ValidationRules
            .ensure('selectedContactType').required().withMessage("Contact Type is required")
            .ensure('phoneNo')
            .required().withMessage("Phone number is required")
            .matches(/^\d{10}$/, { message: 'Phone number must be exactly 10 digits.' })
            .withMessage("Please enter valid phone number")
            .ensure('mobileNo')
            .matches(/^\d{10}$/, { message: 'Mobile number must be exactly 10 digits.' })
            .withMessage("Please enter valid mobile number")
            .ensure('fax')
            .matches(/^\d{10}$/, { message: 'Fax number must be exactly 10 digits.' })
            .withMessage("Please enter valid fax number")
            .ensure('address1').required().withMessage("Address is required")
            .ensure('emailId')
            .matches(/^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/)
            .withMessage('Please enter a valid email address.')
            .ensure('pincode')
            .displayName('Pin code')
            .matches(/^\d+$/, { message: '${$displayName} must contain only numeric values.' })
            .withMessage('Please enter valid pin code.')
            .on(this);
        ValidationRules.customRule(
            'numericPincode',
            (value, obj) => /^\d+$/.test(value),
            'Pin code must contain only numeric values.'
        );
    }
    validatePhoneNumber() {
        this.validationController.validate({ object: this, rules: { phoneNo: true } })
            .then(results => {
                this.phoneNoError = results.filter(result => result.propertyName === 'phoneNo');
            });
    }
    validateMobileNumber() {
        this.validationController.validate({ object: this, rules: { mobileNo: true } })
            .then(results => {
                this.mobileNoError = results.filter(result => result.propertyName === 'mobileNo');
            });
    }
    validateEmailFormat(emailId) {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(emailId)) {
            this.emailIdError = [{ error: { message: 'Invalid email format.' } }];
        } else {
            this.emailIdError = [];
        }
    }
    validate() {
        this.validationController.validate()
            .then(results => {
                this.pincodeError = results.filter(result => result.propertyName === 'pincode');
            });
    }

    async attached(){

        this.profileService.ContactTypeDropdown()
            .then(data => {
                this.types= Object.entries(data).map(([key, value])=>({value, key}));
                this.filterAddressTypes();
            });
        this.profileService.CountryCodesDropdown()
            .then(data => {
                this.codes= data;
            });
        this.profileService.CountryNamesDropdown()
            .then(data => {
                this.countryNames= data;
            });
        this.profileService.CountryStatesDropdown()
            .then(data => {
                this.states= data;
            });
    }
    async filterAddressTypes(){
        const contactDetails = await this.profileService.GetContactDetails(this.empID);
        contactDetails.map(item =>{
            this.existingContact.push(item.ContactType);
        });
        this.filteredAddressTypes = this.types.filter(
            type=> !this.existingContact.includes(type.value)
        );
    }
    async selectCountry(data){
      const states = await this.profileService.GetStatesDropdown();
      this.statesDropdown = states.filter(option => option.CountryID == data );
    }
    cancel() {
        this.controller.cancel();
    }
    saveContact(){
        const refreshEvent = new CustomEvent('refreshData');
        event.preventDefault();
        if (this.mobileNo === null){
            this.mobileNo = "";
        }
        if (this.fax=== null){
            this.fax = "";
        }
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    let data = {
                            EmployeeID: this.empID,
                            Phone: this.phoneNo,
                            PhoneContactID: this.PhoneContactID,
                            PhoneCountryCode:this.phonetypeId,
                            CountryCode: this.CountryCode,
                            EmailID: this.emailId,
                            Extension: this.Extension ,
                            ContactTypeId:this.selectedContactType,
                            ContactID:this.ContactID,
                            Fax:this.fax,
                            FaxContactID:this.faxcontactid,
                            FaxCountryCode:this.FaxCountryCode,
                            Mobile:this.mobileNo,
                            MobileContactID:this.mobilecontactid,
                            MobileCountryCode:this.MobileCountryCode,
                    };
                    this.profileService.ContactDetailsSave(data)
                     .then(() => {
                         this.controller.cancel();
                         this.profileService.GetContactDetails(this.empID,)
                             .then(data => {
                                 this.contactDetailsData = data;
                                 document.dispatchEvent(refreshEvent);
                                  });
                     });
                }

            });
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    saveAddress(){
        const refreshEvent = new CustomEvent('refreshData');
        event.preventDefault();
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    let data = {
                    EmployeeID: this.empID,
                    Address1: this.address1,
                    Address2: this.address2,
                    City: this.city,
                    StateID: this.state,
                    CountryID: this.country ,
                    PinCode:this.pincode,
                    ContactTypeId:this.selectedContactType,
                    AddressID:this.addressID,
                    };
                    this.profileService.AddressDetailsSave(data)
                        .then(() => {
                            this.controller.cancel();
                            this.profileService.GetContactDetails(this.empID)
                                .then(data => {
                                    this.contactDetailsData = data;
                                    document.dispatchEvent(refreshEvent);
                                });
                        });
                }
                else{
                    const errorMessages = document.querySelectorAll(".error-messages");
                    if (errorMessages.length > 0) {
                        errorMessages[0].scrollIntoView({ behavior: "smooth" });
                    }
                }
            });
    }
}
