import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import { JwtService } from '../../../../shared/services/jwtservice';
import {ValidationControllerFactory, ValidationRules,validateTrigger} from 'aurelia-validation';
import Scrollbar from 'smooth-scrollbar';
import {LoginService} from "../../../../shared/services/loginservice";
import { Router } from 'aurelia-router';
@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService)
export class EditEmergencyComponent {
    types = [];
    contactnamevalidError = false;
    isAnyContactProvided = true;
    mobile = "";
    phone = "";
    workcontact = "";
    isValidationMessageVisible = false; 

    constructor(controller, ProfileService,JwtService,controllerFactory,router,loginService) {
        this.controller = controller;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.controllerFactory.validateTrigger = validateTrigger.change;
        this.phone = '';
        this.phoneError = [];
        this.mobile = '';
        this.mobileError = [];
        this.workcontact = '';
        this.workcontactError = [];
        this.pincode = '';
        this.pincodeError = [];
        this.router = router;
        this.loginService = loginService;
        this.empID = this.jwtService.getEmployeeId();
        ValidationRules
            .ensure('name').required().withMessage("Name is required")
            // .matches(/^[A-Za-z]+$/)
            .matches(/^[A-Za-z\s]+$/)
            .withMessage('Please enter characters only.')
            .ensure('address1').required().withMessage("Address is required")
            .ensure('phone')
            .matches(/^\d{10}$/, { message: 'Phone number must be exactly 10 digits.' })
            .withMessage("Please enter valid phone number")
            .ensure('mobile')
            .matches(/^\d{10}$/)
            .withMessage('Please enter valid mobile number')
            .ensure('workcontact')
            .matches(/^\d{10}$/, { message: 'Work contact number must be exactly 10 digits.' })
            .withMessage("Please enter valid work contact number")
            .ensure('workectension')
            .maxLength(6)
            .withMessage('Work phone extension field maximum length is 6.')
            .ensure('pincode')
            .displayName('Pin code')
            .matches(/^\d+$/, { message: '${$displayName} must contain only numeric values.' })
            .withMessage('Please enter valid pin code.')
            .on(this);
    }
    validateMobileNumber() {
        this.validationController.validate({ object: this, rules: { mobile: true } })
            .then(results => {
                this.mobileError = results.filter(result => result.propertyName === 'mobile');
            });
    }
    validatePhoneNumber() {
        this.validationController.validate({ object: this, rules: { phone: true } })
            .then(results => {
                this.phoneError = results.filter(result => result.propertyName === 'phone');
            });
    }
    validateWorkcontactNumber() {
        this.validationController.validate({ object: this, rules: { workcontact: true } })
            .then(results => {
                this.workcontactError = results.filter(result => result.propertyName === 'workcontact');
            });
    }
    validateField(data){
        if(data)
        {
          // console.log("Hello");
           this.controllerFactory.clear();
        }
    }
    validate() {
        this.validationController.validate()
            .then(results => {
                this.pincodeError = results.filter(result => result.propertyName === 'pincode');
            });
    }
    areAllContactsEmpty() {
        return !this.mobile && !this.phone && !this.workcontact;
    }
    handleInput() {
        if (!this.areAllContactsEmpty()) {
            this.isValidationMessageVisible = false; // Hide the validation message
        }
    }
    countryMatcher = (a, b) => {
      return a.key === b.key;
    };
    stateMatcher = (a, b) => {
      return a.key === b.key;
    };
    async bind() {
    setTimeout(()=>{       
      Scrollbar.init(document.querySelector('.contact-history-scrollbar'));
    },100)
    }
    attached() {
        this.profileService.EmployeeFamilyRelationship()
            .then(data => {
                this.options= data;
            });
        this.profileService.CountryCodesDropdown()
            .then(data => {
                this.codes = data;
                this.phoneCodeMatched = (a, b) => {
                  return a.CountryCodeID == b.key;
                }
            });
        this.profileService.CountryNamesDropdown()
            .then(data => {
                this.countryNames = data;
            });
        this.profileService.CountryStatesDropdown()
            .then(data => {
                this.states = data;
            });
        this.profileService.GetEmergencyContactDetails(this.empID)
            .then(data => {
                this.emergencycontactdata = data;
            });
    }
    cancel() {
        this.controller.cancel();
    }
    
    async activate(data){
        this.emergencycontactdata = data;
        this.address1 = data.Address1;
        this.address2 = data.Address2;
        this.name = data.Name;
        this.Relationship = data.EmployeeDependentConfigID;
        this.mobile = data.Mobile;
        this.mobileid = data.MobileCountryCode;
        this.phone = data.HomeContactNo;
        this.phoneid = data.HomeCountyCode;
        this.workcontact = data.WorkContactNo;
        this.workcontactid = data.WorkCountryCode;
        this.workectension = data.WorkExtension;
        this.city = data.City;
        this.Country = data.CountryID;
        this.StateNames = data.StateID;
        this.pincode = data.PinCode;
        this.AddressID = data.AddressID;
        this.PersonId = data.PersonId;
        this.PersonEmergencyContactID = data.PersonEmergencyContactID;
        this.HomeExtension = data.HomeExtension;
        await this.selectCountry(this.Country);
    }
    async selectCountry(data){
      const states = await this.profileService.GetStatesDropdown();
      const countryId = data.CountryID ? data.CountryID : data;
      this.statesDropdown = states.filter(option => option.CountryID === countryId );
    }
    SaveEmergencyContact(){
        const refreshEvent = new CustomEvent('refreshDataEditEmergencyContact');
        event.preventDefault();
        this.controllerFactory.validate()
            .then(result => {
                if (this.areAllContactsEmpty()) {
                    this.isValidationMessageVisible = true;
                    const errorMessages = document.querySelectorAll(".error-messages");
                    if (errorMessages.length > 0) {
                        errorMessages[0].scrollIntoView({ behavior: "auto" });
                    }
                } else {
                    this.isValidationMessageVisible = false;
                    if (result.valid && !this.contactnamevalidError) {
                        let data = {
                            EmployeeID: this.empID,
                            Address1: this.address1,
                            Address2: this.address2,
                            City: this.city,
                            PinCode: this.pincode,
                            AddressID: this.AddressID,
                            PersonId: this.PersonId,
                            PersonEmergencyContactID: this.PersonEmergencyContactID,
                            StateID: this.selectedState ? this.selectedState.StateID : this.StateNames,
                            CountryID: this.selectedCountry ? this.selectedCountry.CountryID : this.Country,
                            Name: this.name,
                            EmployeeDependentConfigID: this.Relationship,
                            HomeContactNo: this.phone,
                            Mobile: this.mobile,
                            WorkContactNo: this.workcontact,
                            HomeCountyCode: this.phoneCountryCodeId ? this.phoneCountryCodeId.CountryCodeID : this.phoneid,
                            MobileCountryCode: this.mobileCountryCodeid ? this.mobileCountryCodeid.CountryCodeID : this.mobileid,
                            WorkCountryCode: this.workcontactCountryCodeId ? this.workcontactCountryCodeId.CountryCodeID : this.workcontactid,
                            HomeExtension: this.HomeExtension,
                            WorkExtension: this.workectension,
                        };
                        const EmergencyContactValidatecheck = {
                            EmployeeID: this.empID,
                            Name: this.name,
                            PersonEmergencyContactID: this.PersonEmergencyContactID,
                        };
                        this.profileService.EmergencyContactAvoidDuplicate(EmergencyContactValidatecheck).then(name => {
                            if (name === 1) {
                                this.errorMessage = true;
                                this.errorMessage = 'Name already exists.';
                                setTimeout(() => {
                                    this.errorMessage = null
                                }, 5000);
                            }
                            else {
                                this.profileService.SaveEmergencyContact(data)
                                    .then(() => {
                                        this.controller.cancel();
                                        this.profileService.GetEmergencyContactDetails(this.empID)
                                            .then(data => {
                                                this.emergencycontactdata = data;
                                                document.dispatchEvent(refreshEvent); // Trigger the custom event
                                            });
                                    });
                            }
                        });
                    }
                    else{
                        const errorMessages = document.querySelectorAll(".error-messages");
                        if (errorMessages.length > 0) {
                            errorMessages[0].scrollIntoView({ behavior: "auto" });
                        }}

                }
});
    }

}
