import {inject } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { ProfileService } from '../../../../shared/services/profielservice';
import {JwtService} from "../../../../shared/services/jwtservice";
import {ValidationControllerFactory, ValidationRules} from 'aurelia-validation';
import { Router } from 'aurelia-router';
import {LoginService} from "../../../../shared/services/loginservice";

@inject(DialogController,ProfileService,JwtService,ValidationControllerFactory,Router,LoginService)
export class Editcontactcomponent {
    types = [];
    tempHide = false;
    isDragging = false;
    dialog;
    static inject = [Element];

    constructor(controller,ProfileService,JwtService,controllerFactory,router,loginService){
        this.controller = controller;
        this.profileService=ProfileService;
        this.jwtService = JwtService;
        this.controllerFactory = controllerFactory.createForCurrentScope();
        this.empID = this.jwtService.getEmployeeId();
        this.phone = '';
        this.phoneError = [];
        this.mobile = '';
        this.mobileError = [];
        this.fax = '';
        this.faxError = [];
        this.pincode = '';
        this.pincodeError = [];
            this.router = router;
            this.loginService = loginService;

        ValidationRules
            .ensure('selectedContactType').required().withMessage("Contact Type is required")
            .ensure('phone').required().withMessage("Phone number is required")
            .matches(/^\d{10}$/, { message: 'Phone number must be exactly 10 digits.' })
            .withMessage("Please enter valid phone number")
            .ensure('mobile')
            .matches(/^\d{10}$/, { message: 'Mobile number must be exactly 10 digits.' })
            .withMessage("Please enter valid mobile number")
            .ensure('fax')
            .matches(/^\d{10}$/, { message: 'Fax number must be exactly 10 digits.' })
            .withMessage("Please enter valid fax number")
            .ensure('address1').required().withMessage("Address is required")
            .ensure('email')
            .matches(/^[^\s@]+@[^\s@]+\.(com|co|in|org|cc|net)$/)
            .withMessage('Please enter a valid email address.')
            .ensure('pincode')
            .displayName('Pin code')
            .matches(/^\d+$/, { message: '${$displayName} must contain only numeric values.' })
            .withMessage('Please enter valid pin code.')
            .on(this);

    }
    validatePhoneNumber() {
        this.validationController.validate({ object: this, rules: { phone: true } })
            .then(results => {
                this.phoneError = results.filter(result => result.propertyName === 'phone');
            });
    }
    validateMobileNumber() {
        this.validationController.validate({ object: this, rules: { mobile: true } })
            .then(results => {
                this.mobileError = results.filter(result => result.propertyName === 'mobile');
            });
    }
    validateEmailFormat(email) {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        if (!emailRegex.test(email)) {
            this.emailError = [{ error: { message: 'Invalid email format.' } }];
        } else {
            this.emailError = [];
        }
    }
    validate() {
        this.validationController.validate()
            .then(results => {
                this.pincodeError = results.filter(result => result.propertyName === 'pincode');
            });
    }
    countryMatcher = (a, b) => {
      return a.key === b.key;
    };
    phoneCodeMatched = (a, b) => {
      return a.key === b.key;
    };
    async activate(data){
        this.ContacatTypeID =data.ContactTypeId;
        if(this.ContacatTypeID){
            this.ContactTypeName = data.ContactType;
            this.tempHide = false;
        }
        this.PersonContactTypeId = data.PersonContactTypeId;
        this.PersonId = data.PersonId;
        await this.profileService.EditEmployeeAddress(this.ContacatTypeID,this.PersonContactTypeId,this.PersonId)
        .then(data => {
            this.addressdata = data;
            this.address1 = data.Address1;
            this.address2 = data.Address2;
            this.city = data.City;
            this.countryID = data.CountryID;
            this.StateNames = data.StateID;
            this.pincode = data.PinCode;
            this.AddressID = data.AddressID;
            this.selectCountry(this.countryID);
        });
        await this.profileService.EditEmployeeContact(this.ContacatTypeID,this.PersonContactTypeId,this.PersonId)
            .then(data => {
                this.contactdata = data;
                this.phone = data.Phone;
                this.phoneid = data.PhoneCountryCode;
                this.Extension = data.Extension;
                this.mobile = data.Mobile;
                this.mobileCountryCode = data.MobileCountryCode;
                this.fax = data.Fax;
                this.faxCountryCode = data.FaxCountryCode;
                this.email = data.EmailID;
                this.PhoneContactID= data.PhoneContactID;
                this.FaxContactID= data.FaxContactID;
                this.MobileContactID= data.MobileContactID;
            });
    }
    async attached(){

        this.profileService.ContactTypeDropdown()
            .then(data => {
                this.types= Object.entries(data).map(([key, value])=>({value, key}));
            });
        this.profileService.CountryCodesDropdown()
            .then(data => {
                this.codes= data;
            });
         this.profileService.CountryNamesDropdown()
            .then(data => {
                this.countryNames= data;
            });
         this.profileService.CountryStatesDropdown()
            .then(data => {
                this.states= data;
            });
    }
    async selectCountry(data){
      const states = await this.profileService.GetStatesDropdown();
      const countryId = data.CountryID ? data.CountryID : data;
      this.statesDropdown = states.filter(option => option.CountryID === countryId );
    }
    cancel() {
        this.controller.cancel();
    }
    startDragging(event) {
        if (event.button === 0) {
            this.isDragging = true;
            this.offsetX = event.clientX - this.dialogElement.getBoundingClientRect().left;
            this.offsetY = event.clientY - this.dialogElement.getBoundingClientRect().top;

            window.addEventListener('mousemove', this.drag);
            window.addEventListener('mouseup', this.stopDragging);
        }
    }

    drag = (event) => {
        if (this.isDragging) {
            const x = event.clientX - this.offsetX;
            const y = event.clientY - this.offsetY;

            this.dialogElement.style.left = `${x}px`;
            this.dialogElement.style.top = `${y}px`;
        }
    }

    stopDragging = () => {
        this.isDragging = false;
        window.removeEventListener('mousemove', this.drag);
        window.removeEventListener('mouseup', this.stopDragging);
    }
    SaveConatactDetails(){
        const refreshEvent = new CustomEvent('refreshEditData');
        event.preventDefault();
        if (this.mobile === null){
            this.mobile = "";
        }
        if (this.fax=== null){
            this.fax = "";
        }
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
        let data = {
            EmployeeID: this.empID,
            Phone: this.phone,
            PhoneContactID: this.PhoneContactID,
            PhoneCountryCode:this.phonetypeId ? this.phonetypeId.CountryCodeID : this.phoneid,
            CountryCode: this.CountryCode,
            EmailID: this.email,
            Extension: this.Extension ,
            ContactTypeId:this.ContacatTypeID,
            ContactTypeName:this.ContactTypeName,
            ContactID:this.ContactID,
            Fax:this.fax,
            FaxContactID:this.FaxContactID,
            FaxCountryCode:this.faxCountryCodeId ? this.faxCountryCodeId.CountryCodeID : this.faxCountryCode,
            Mobile:this.mobile,
            MobileContactID:this.MobileContactID,
            MobileCountryCode:this.mobileCountryId ? this.mobileCountryId.CountryCodeID : this.mobileCountryCode,

        };
        this.profileService.ContactDetailsSave(data)
            .then(() => {
                this.controller.cancel();
                this.profileService.GetContactDetails(this.empID)
                    .then(data => {
                        this.contactDetailsData = data;
                        document.dispatchEvent(refreshEvent);
                    });
            });
                }
        });
    }
    SaveUpdateAddress(){
        const refreshEvent = new CustomEvent('refreshEditData');
        const State = document.getElementById('state').value;
        const countryid = this.selectedCountry ? this.selectedCountry.CountryID : this.countryID;
        event.preventDefault();
        this.controllerFactory.validate()
            .then(result => {
                if (result.valid) {
                    let data = {
                        EmployeeID: this.empID,
                        Address1: this.address1,
                        Address2: this.address2,
                        City: this.city,
                        StateID: State,
                        CountryID: countryid ,
                        PinCode:this.pincode,
                        ContactTypeId:this.ContacatTypeID,
                        AddressID:this.AddressID,
                 };
                    this.profileService.AddressDetailsSave(data)
                    .then(() => {
                        this.controller.cancel();
                        this.profileService.GetContactDetails(this.empID)
                        .then(data => {
                        this.contactDetailsData = data;
                        document.dispatchEvent(refreshEvent);
                    });
            });

                }
        });
    }
}
