import {inject } from 'aurelia-framework';
import {Addcontactcomponent} from "./addcontact/addcontactcomponent";
import {Editcontactcomponent} from "./editcontact/editcontactcomponent";
import {AddEmergencyComponent} from "./addemergencycontact/addemergencycomponent";
import {EditEmergencyComponent} from "./editemergencycontact/editemergencycomponent";
import {DialogService} from 'aurelia-dialog';
import {ProfileService} from "../../../shared/services/profielservice";
import {JwtService} from "../../../shared/services/jwtservice";
import {AlertpopupComponent} from "../../alertpopup/alertpopupcomponent";
import {ShowHistoryComponent} from "./showemergencyhistory/showhistorycomponent";
import Scrollbar from 'smooth-scrollbar';
import { Router } from 'aurelia-router';
import {config} from '../../../shared/services/config';
import {LoginService} from "../../../shared/services/loginservice";

@inject(ProfileService,JwtService,DialogService,Router,LoginService)
export class Contactdetails {
    contactsucces = false;
    emergencymessage = false;
    deleteemergencymessage =false;
    deleteemployeemessage = false;
    pageSize = config.grid_PazeSize;
    topicsPageSize=10;
    contactPageSize = 10;

    constructor(ProfileService,JwtService,DialogService,router,loginService) {
        this.dialogService = DialogService;
        this.profileService = ProfileService;
        this.jwtService = JwtService;
        this.router = router;

        this.loginService = loginService;
        this.empID = this.jwtService.getEmployeeId();
    }

    activate(){
        this.contactDetailsData = this.FetchContactDetailsData();
        this.contactDetailsData = this.FetchContactDetailsDataforEdit();
        this.emergencycontactdata = this.FetchEmergencyContactDetailsData();
        this.emergencycontactdata = this.FetchEditEmergencyContactDetailsData();
    }
    async bind() {
        setTimeout(()=>{       
            Scrollbar.init(document.querySelector('.emergencycontact-scrollbar'));
            Scrollbar.init(document.querySelector('.contacthistory-scrollbar'));
        },100)
    }

    async attached(params) {
        this.isLoading = true;
        const id = this.router.currentInstruction.queryParams.id;
        if(id != null && id !='' && id !=undefined)
            {
                this.empID=id;
            }
            else{
                this.empID=this.jwtService.getEmployeeId();
            }

        document.addEventListener('refreshData', this.FetchContactDetailsData);
        document.addEventListener('refreshEditData', this.FetchContactDetailsDataforEdit);
        const contactDetails = await this.profileService.GetContactDetails(this.empID);
        this.contactDetailsData = contactDetails;
        const emergencycontacts = await this.profileService.GetEmergencyContactDetails(this.empID);
        this.emergencycontactdata = emergencycontacts;
        document.addEventListener('refreshDataEmergencyContact', this.FetchEmergencyContactDetailsData);
        document.addEventListener('refreshDataEditEmergencyContact', this.FetchEditEmergencyContactDetailsData);
        const emergencycontacthistory = await this.profileService.GetEmergencyContactHistory(this.empID);
        this.emergencyhistory = emergencycontacthistory;
        this.isLoading = false;
        // setTimeout(() => {
        //     this.isLoading = false;
        // }, 3000);
    }
    FetchContactDetailsData = () =>{
        this.profileService.GetContactDetails(this.empID)
            .then(data => {
                this.contactDetailsData = data;
                this.sucess = "Contact details added successfully";
                this.contactsucces = true;
                setTimeout(() =>{
                    this.sucess = null;
                    },4000)
            });
    };
    FetchContactDetailsDataforEdit = () =>{
        this.profileService.GetContactDetails(this.empID)
            .then(data => {
                this.contactDetailsData = data;
                this.sucess = "Contact details updated successfully";
                this.contactsucces = true;
                this.contactUpdatemessage = false;
                setTimeout(() =>{
                    this.sucess = null;
                },4000)
            });
    };
     FetchEmergencyContactDetailsData = () =>{
                this.profileService.GetEmergencyContactDetails(this.empID)
                .then(data => {
                this.emergencycontactdata = data;
                    this.Eergencysucess = "Emergency contact added successfully";
                    this.emergencymessage = true;
                    setTimeout(() =>{
                        this.Eergencysucess = null;
                    },4000)
                });
        };
    FetchEditEmergencyContactDetailsData = () =>{
        this.profileService.GetEmergencyContactDetails(this.empID)
            .then(data => {
                this.emergencycontactdata = data;
                this.Eergencysucess = "Emergency contact updated successfully";
                this.emergencymessage = true;
                this.emergencyUpdatemessage = false;
                setTimeout(() =>{
                    this.Eergencysucess = null;
                },4000)
            });
    };

    AddnewcontactPopup(data) {
        this.dialogService.open({viewModel: Addcontactcomponent, model: data});
    }
    EditcontactPopup(data) {
        this.dialogService.open({viewModel: Editcontactcomponent, model: data});
    }
    AddEmergencyContactPopup(data) {
        this.dialogService.open({viewModel: AddEmergencyComponent, model: data});
    }
    EditEmergencyContactPopup(data) {
        this.dialogService.open({viewModel: EditEmergencyComponent, model: data});
    }
    BindHistoryData(data) {
        this.dialogService.open({viewModel: ShowHistoryComponent, model: data});
    }
    async deleteEmployeeContact(data) {
        this.contactid = data.ContactTypeId;
        if(data.ContactTypeId === 7 ){
            return  alert("The business contact is required.")
        }
        this.personid = data.PersonId;
        const result = await this.dialogService.open({
            viewModel: AlertpopupComponent,
            model: "",
        }).whenClosed(response => response);
        if (result.wasCancelled) {
            return;
        }
        await this.profileService.DeleteEmployeeContact(this.contactid,this.personid,this.empID);
        const employeeContactData = await this.profileService.GetContactDetails(this.empID);
        this.contactDetailsData = employeeContactData;
        this.Deleteemployee = "Contact details deleted successfully";
        this.deleteemployeemessage = true;
        setTimeout(() =>{
            this.Deleteemployee = null;
        },4000)
    }
    async deleteEmergencyContact(data) {
        this.PersonEmergencyContactID = data.PersonEmergencyContactID;
        const result = await this.dialogService.open({
            viewModel: AlertpopupComponent,
            model: "",
        }).whenClosed(response => response);
        if (result.wasCancelled) {
            return;
        }
        await this.profileService.DeleteEmergencyContact(this.PersonEmergencyContactID,this.empID);
        const emergencyContactData = await this.profileService.GetEmergencyContactDetails(this.empID);
        this.emergencycontactdata = emergencyContactData;
        const emergencycontacthistory = await this.profileService.GetEmergencyContactHistory(this.empID);
        this.emergencyhistory = emergencycontacthistory;
        this.Deleteemergency = "Emergency contact deleted successfully";
        this.deleteemergencymessage = true;
        setTimeout(() =>{
            this.Deleteemergency = null;
        },4000)
    }

}
